<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.save.click">保存</en-button>
    </template>
    <flex-box>
      <template #default="{ height }">
        <en-card>
          <div>1.实时上传：工单结算后，系统自动上传符合要求的维修工单。</div>
          <div>2.工单中必须含有当前里程、VIN、工时、故障描述四项内容且该工单已结算才可上传，否则不上传。</div>
          <div>
            3.交通部采集的数据为：维修企业名称、车辆识别码、车牌号、里程、送修日期、结算日期、结算单据编号、故障描述、维修项目、配件名称配件数量。
          </div>
          <div>4.工单金额、维修人员、车主信息等其他工单信息不会上传。</div>
        </en-card>

        <en-form :model="detail.form.data" :loading="detail.form.loading" :ref="setRef('detailForm')" :rules="detail.form.rules">
          <en-form-item label="开启汽车档案上传" prop="enabled">
            <en-radio label="Y" v-model="detail.form.data.enabled.code">是</en-radio>
            <en-radio label="N" v-model="detail.form.data.enabled.code">否</en-radio>
          </en-form-item>
          <div class="grid grid-cols-4 gap-4">
            <en-form-item label="企业名称" prop="companyName" v-if="detail.form.data.enabled.code === 'Y'">
              <en-input v-model="detail.form.data.companyName"></en-input>
            </en-form-item>
            <en-form-item label="所属省份" prop="province" v-if="detail.form.data.enabled.code === 'Y'">
              <select-maintain
                v-model="detail.form.data.province"
                :ajax="{
                  action: 'GET /enocloud/elecdoc/province'
                }"
                :props="{ label: 'name', value: '' }"
                value-key="id"
                class="w-full"
                @change="detail.form.change.click"
              ></select-maintain>
            </en-form-item>
            <en-form-item label="企业编码" prop="code" v-if="detail.form.data.enabled.code === 'Y'">
              <en-input v-model="detail.form.data.code"></en-input>
            </en-form-item>
            <en-form-item label="档案密码" prop="password" v-if="detail.form.data.enabled.code === 'Y'">
              <en-input type="password" v-model="detail.form.data.password"  show-password></en-input>
            </en-form-item>
          </div>
          <en-form-item label="上传模式" prop="uploadMode" v-if="detail.form.data.enabled.code === 'Y'">
            <en-radio label="A" v-model="detail.form.data.uploadMode.code">实时上传</en-radio>
            <en-radio label="M" v-model="detail.form.data.uploadMode.code">手动上传</en-radio>
            <span
              >（说明：如果是实时上传，工单变为已结算的时候，上传到对应省级系统） 请先选择省份，点此链接【健康档案官网】申请企业编码和档案密码)
            </span>
          </en-form-item>
        </en-form>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        save: {
          async click() {
            await this.detail.form.submit()
            await this.detail.form.get()
          }
        }
      },
      detail: {
        children: {
          form: {
            is: 'form',
            data: {
              enabled: {
                message: '',
                code: 'Y'
              },
              province: {},
              uploadMode: { code: 'A' },
              password: '',
              code: '',
              companyName: '',
              homepage: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/elecdoc',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data]
                },
                convert(data: any) {
                  data.homepage = data.province.homepage
                  return data
                }
              },
              submit: {
                action: 'PUT /enocloud/elecdoc',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
              province: [{ required: true, message: '请选择所属省份', trigger: 'change' }],
              code: [{ required: true, message: '请输入企业编码', trigger: 'blur' }],
              password: [{ required: true, message: '请输入档案密码', trigger: 'blur' }]
            },
            change: {
              click(row: any) {
                this.detail.form.data.homepage = row.homepage
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.detail.form.get()
    this.detail.form.submit()
  }
})
</script>
